import React, { FC, ReactNode, createElement } from 'react'
import { Text } from 'rebass'
import PageHeading from './PageHeading'
import ContainerFixed from '../ui/ContainerFixed'

interface AstNode {
  type: 'text' | 'element'
  tagName?: string
  value?: string
  children?: AstNode[]
  properties: any
}

const renderNode = (node: AstNode, key: number | string, parent?: AstNode): ReactNode => {
  if (node.type === 'text') {
    if (parent?.tagName === 'h2') {
      const match = node.value?.match(/^(.*)(\s+)\{#(.*)\}$/)
      if (match) {
        node.value = match[1]
        parent.properties.id = match[3]
      }
    }

    return node.value
  }

  const props = {
    key,
    id: node.properties.id || undefined,
    children: node.children ? renderNodes(node.children, node) : undefined,
  }

  switch (node.tagName) {
    case 'h1':
      return (
        <PageHeading
          mb={4}
          {...props}
        />
    )

    case 'h2':
      return (
        <Text
          mt={4}
          mb={2}
          as="h2"
          color="text"
          fontSize={[24, 24, 32]}
          {...props}
        />
      )

    case 'p':
    case 'li':
      return (
        <Text
          mb={3}
          as={node.tagName}
          color="text"
          fontSize={18}
          {...props}
        />
      )

    default:
      return createElement(node.tagName || 'div', props)
  }
}

const renderNodes = (nodes: AstNode[], parent?: AstNode) => nodes.map((node, i) => renderNode(node, i, parent))

type Props = {
  nodes: AstNode[]
}

const PrivacyPolicy: FC<Props> = ({ nodes }) => {
  return (
    <ContainerFixed my={5}>
      {renderNodes(nodes)}
    </ContainerFixed>
  )
}

export default PrivacyPolicy
